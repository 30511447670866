import React from 'react'
import { PageProps } from 'gatsby'

import Layout from 'src/layouts'
import Products from '../components/Products'
import SEO from '../components/shared/SEO'

const ProductsPageAlternate: React.FC<PageProps> = ({ location }) => (
  <>
    <SEO title="Our Products" location={location} />

    <Layout location={location}>
      <Products isAlternate />
    </Layout>
  </>
)

export default ProductsPageAlternate
